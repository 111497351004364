* {
  margin: 0;
  padding: 0;
}

.imageframe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-enabled {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.fullscreen-enabled .fsTarget {
  margin: 0 auto;
}

.fitimage {
  object-fit: contain;
  vertical-align: middle;
}

.printArea {
  display: none !important;
}

.hide {
  display: none !important;
}

@media print {
	.printSwitch:not(.showPrint),
  .hidePrint {
		display: none !important;
	}

  .printArea {
    display: block !important;
  }
}

@page {
  size: landscape;
}
